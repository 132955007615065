import "D:\\code\\x-admin\\yyy\\x-admin-frontend\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\code\\x-admin\\yyy\\x-admin-frontend\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\code\\x-admin\\yyy\\x-admin-frontend\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\code\\x-admin\\yyy\\x-admin-frontend\\node_modules\\core-js\\modules\\es.promise.finally.js";
console.log("进入main.js");
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
ElementUI.Dialog.props.closeOnClickModal.default = false;
import Pagination from '@/components/Pagination';
import BasicLayout from '@/layout/BasicLayout';
import VueParticles from 'vue-particles';
Vue.use(VueParticles);

// vue中导出excel表格模板
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
Vue.prototype.$FileSaver = FileSaver; //设置全局
Vue.prototype.$XLSX = XLSX; //设置全局

import { tabledata } from '@/api/invoice/tableData';
Vue.prototype.tabledata = tabledata;
import '@/styles/index.scss'; // global css
import '@/styles/admin.scss';
import App from './App';
import store from './store';
import router from './router';
import permission from './directive/permission';
import Viser from 'viser-vue'; // v-chart 相关组件
Vue.use(Viser);
import VueClipBoard from 'vue-clipboard2';
Vue.use(VueClipBoard);
import { parseTime, resetForm, addDateRange, download, selectDictLabel, selectItemLabelV2, selectItemLabelV3, dateFormat, closePage, copyInvoice } from '@/utils/costum';
import '@/icons'; // icon
import './permission'; // permission control
import VueUeditorWrap from 'vue-ueditor-wrap';
Vue.component('vue-ueditor-wrap', VueUeditorWrap);

// 全局方法挂载
Vue.prototype.resetForm = resetForm;
Vue.prototype.parseTime = parseTime;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.download = download;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectItemLabelV2 = selectItemLabelV2;
Vue.prototype.selectItemLabelV3 = selectItemLabelV3;
Vue.prototype.closePage = closePage;
Vue.prototype.dateFormat = dateFormat;
Vue.prototype.copyInvoice = copyInvoice;
Vue.prototype.apiUrl = process.env.VUE_APP_BASE_API;
Vue.prototype.fxApiUrl = process.env.VUE_APP_SHARE_API;
Vue.prototype.fpApiUrl = process.env.VUE_APP_INVOICE_API;

// 全局组件挂载
Vue.component('Pagination', Pagination);
Vue.component('BasicLayout', BasicLayout);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  var _require = require('../mock'),
    mockXHR = _require.mockXHR;
  mockXHR();
}
Vue.use(permission);
// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(ElementUI);

// 滚动无限加载
import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});